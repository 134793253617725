import { Patient } from "@/apis/entity/Patient";
import { createStorage } from "@/utils/Storage";
import { defineStore } from "pinia";
import { ACCESS_TOKEN } from "../mutation-types";

const Storage = createStorage({ storage: localStorage })

export interface IUserState {
  token: string;
  loginInfo?: Patient;
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): IUserState => ({
    token: Storage.get(ACCESS_TOKEN, ''),
    loginInfo: undefined
  }),
  getters: {
    getToken(): string {
      return this.token
    },
    getLoginInfo(): Patient | undefined {
      return this.loginInfo
    }
  },
  actions: {
    setToken(token: string) {
      Storage.set(ACCESS_TOKEN, token)
      this.token = token
    },
    setLoginInfo(loginInfo) {
      this.loginInfo = loginInfo
    }
  }
})